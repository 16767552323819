import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Guid } from '../guid';

@Injectable()
export class OAuthCustomStorageService extends OAuthService {
  protected storeAccessTokenResponse(
    accessToken: string,
    refreshToken: string,
    expiresIn: number,
    grantedScopes: string
  ) {
    super.storeAccessTokenResponse(
      accessToken,
      refreshToken,
      expiresIn,
      grantedScopes
    );
    this._storage.setItem('auth_data_updated', Guid.newGuid().toString());
    this._storage.removeItem('auth_data_updated');
  }
}
